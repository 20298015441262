:root {
  --background-color: #000000;
  --foreground-color: #ffffff;
  --focus-color: #808080;
}

::-webkit-scrollbar {
  width: 10px;
  height: 10px;
}
::-webkit-scrollbar-track {
  background: #f1f1f1;
}
::-webkit-scrollbar-thumb {
  background: #888;
  border: none;
}
::-webkit-scrollbar-thumb:hover {
  background: #555;
}

::-moz-scrollbar {
  width: 10px;
  height: 10px;
}
::-moz-scrollbar-track {
  background: #f1f1f1;
}
::-moz-scrollbar-thumb {
  background: #888;
  border: none;
}
::-moz-scrollbar-thumb:hover {
  background: #555;
}

.MuiIconButton-root {
  border-radius: 0 !important;
}

.MuiIconButton-root .MuiTouchRipple-root span {  
  border-radius: 0 !important;
}

.MuiFormControl-root {
  width: 100% !important;
}

.yellow {
  color: yellow;
}

/* div {
  font-size: 110%;
}

.large {
  font-size: 150%;
} */

/* .narrow {
  font-size: 2.5em;
}

 */
