.app-body {
  height: 100%;
  display: flex;
}

.app-body.narrow {
  flex-direction: column;
}

.app-index {
  overflow: auto;
  width: 25vw;
  height: 100%;
  flex: 0 0 auto;
  display: flex;
  flex-direction: row;
  padding: 4px;
  border: 1px solid grey;
}

.app-index.narrow {
  width: 100vw;
  height: 100%;
  display: flex;
  flex-direction: row;
}

.app-index.large {
  flex-direction: column;
}

.app-data {
  overflow: auto;
  width: 50vw;
  height: 100%;
  display: flex;
  flex-direction: column;
  padding: 4px;
  border: 1px solid grey;
}

.app-data.drag {
  border: 4px solid rgb(58, 128, 0);
  cursor: copy;
}

.app-data-container {
  height: 100%;
  overflow: auto;
  display: flex;
  flex-direction: column;
}

.app-data-controls {
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column-reverse;
  overflow: auto;
}

.app-file-container {
  width: 100%;
  padding: 3px;
  flex: 1;
}

.app-action {
  overflow: auto;
  width: 25vw;
  height: 100%;
  padding: 4px;
  border: 1px solid grey;
}

.app-action.wide {
  width: 50vw;
}

.app-action-container {
  height: 100%;
  overflow: auto;
}

.app-action-controls {
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column-reverse;
  overflow: auto;
}