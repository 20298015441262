.grid10 {
  display: grid;
  /* grid-template-columns: 5vh 1fr 5vh 5vh 5vh 5vh 5vh 5vh 5vh 5vh; */
  grid-template-columns: 5vh 5vh 5vh 5vh 1fr 5vh 5vh 5vh 5vh 5vh;
  grid-auto-rows: 5vh;
  /* grid-template-rows: 5vh 1fr 5vh; */
  width: 100%;
  /* height: 100%; */
}

.grid10.full {
  height: 100%;
  grid-template-rows: 5vh 1fr 5vh;
}

.grid10.middle {
  /* grid-template-columns: 5vh 5vh 0.5fr 5vh 5vh 5vh 0.5fr 5vh 5vh 5vh;   */
  grid-template-columns: 5vh 1fr 5vh 5vh 5vh 5vh 5vh 5vh 5vh 5vh;
}

.grid10-container {
  position: relative;
  width: 100%;
  height: 100%;
}

.grid10-cell {
  padding-top: 2px;
  padding-bottom: 2px;
  background-color: #1A1A1A;
  overflow: hidden;
}

.grid10-cell-button {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;
  transition: background-color 0.3s, color 0.3s;
}

.grid10-cell-button.disabled {
  cursor: not-allowed;
  color: grey;
}

.grid10-cell-button.disabled:hover {
  cursor: not-allowed;
  color: grey;
  background-color: unset;
}

.grid10-cell-button:hover {
  cursor: pointer;
  background-color: rgba(255, 255, 255, 0.3);
}

.grid10-cell-icon {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: space-around;
  align-items: center;
  line-height: normal;
  position: relative;
}

.grid10-cell-icon .icon {
  z-index: 1;
}

.grid10-cell-icon .swirl-overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 2;
  opacity: 0.9;
  color: white;
  pointer-events: none;
}

.grid10-cell-text {
  width: 100%;
  height: 100%;
  display: flex;
  padding-left: 10px;
  padding-right: 10px;
  font-size: 100%;
}

.grid10-cell-text.center {
  justify-content: center;
}

.grid10-cell-text.right {
  justify-content: right;
}

.grid10-cell-span {
  align-content: center;
  padding-left: 10px;
  padding-right: 10px;
  font-size: 100%;
}

.space {
  border-bottom: 2px solid black;
}

.selected {
  background-color: rgba(255, 255, 255, 0.1);
}

.selected:hover {
  background-color: rgba(255, 255, 255, 0.2);
}

.selected.light {
  background-color: rgba(0, 0, 0, 0.1);
}

.selected.light:hover {
  background-color: rgba(0, 0, 0, 0.1);
}

@keyframes spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

.spin {
  animation: spin 8s linear infinite;
}

@keyframes swirl {
  from {
    transform: rotate(360deg);
    
  }
  to {
    transform: rotate(0deg);
  }
}

.swirl {
  animation: swirl 4s linear infinite;
}