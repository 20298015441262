@keyframes spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.spin {
  animation: spin 10s linear infinite;
}

@keyframes show {
  0% {
    opacity: 0.25;
  }
  100% {
    opacity: 1.0;
  }
}

.show {
  animation: show 250ms;
  animation-fill-mode: forwards;
}

@keyframes hide {
  0% {
    opacity: 1.0;
  }
  100% {
    opacity: 0.25;
  }
}

.hide {
  animation: hide 250ms;
  animation-fill-mode: forwards;
}

@keyframes blink {
  0%,
  100% {
    opacity: 0.25;
  }
  50% {
    opacity: 1.0;
  }
}

.blink {
  animation: blink 2s infinite;
}

.border {
  border: 1px solid rgb(128, 128, 128, 0.125);
}

.border-top {
  border-top: 1px solid rgb(128, 128, 128, 0.125);
}

.border-left {
  border-left: 1px solid rgb(128, 128, 128, 0.125);
}

.border-right {
  border-right: 1px solid rgb(128, 128, 128, 0.125);
}

.border-bottom {
  border-bottom: 1px solid rgb(128, 128, 128, 0.125);
}

.grid {
  display: grid;

}

.grid.center {
  justify-content: center;
}

.grid.float {
  position: absolute;
}

.grid.right {
  right: 0;
}

.grid-cell {
  padding-bottom: 0px;
  background-color: #1A1A1A;  
  overflow: hidden;
}

.grid-cell.light {
  background-color: #FAFAFA;
}

.grid-cell.space::after {
  height: 0px;
  border: none;
  padding-bottom: 10px;
  background-color: unset;
}

.grid-cell-text {
  min-height: 5vh;
  width: 100%;
  height: 100%;
  display: flex;
  padding-left: 10px;
  padding-right: 10px;
  font-size: 100%;
}

.grid-cell-text.narrow {
  font-size: 150%;
}

.grid-cell-text.large {
  font-size: 110%;
}

.grid-cell-text.narrow.large {
  font-size: 250%;
}

.grid-cell-text.x-mid {
  justify-content: center;
}

.grid-cell-text.x-max {
  justify-content: end;
}

.grid-cell-text.y-mid {
  align-content: center;
  flex-wrap: wrap;
}

.grid-cell-text.y-max {
  align-content: end;
}

.grid-form {
  padding: 5px 20px;
}

.MuiGrid-root.MuiGrid-item {
  padding-top: 0px !important;
}

.MuiOutlinedInput-input.MuiInputBase-input {
  min-height: 4.8vh;
  overflow: auto;
  white-space: pre-wrap;
  border-radius: 0;
  padding: 0px 10px;
}

.MuiInputBase-root.MuiOutlinedInput-root {
  min-height: 4.8vh;
  border-radius: 0;
  padding: 0px 10px;
}

.grid-cell-multiline.MuiInputBase-root.MuiOutlinedInput-root {
  height: auto;
}

.grid-cell-icon {
  display: flex;
  justify-content: space-around;
  align-items: center;
  line-height: normal;
  width: 100%;
  height: 100%;
  position: relative;
}

.grid-cell-icon .icon {
  z-index: 1;
}

.grid-cell-icon .swirl-overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 2;
  opacity: 0.7;
}

.grid-cell-button {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-evenly;
  cursor: pointer;
  transition: background-color 0.3s, color 0.3s;
  width: 100%;
  height: 100%;  
}

.grid-cell-button.x-mid {
  align-items: center;
}

.grid-cell-button.disabled {
  cursor: not-allowed;
  color: grey;
}

.grid-cell-button.disabled:hover {
  cursor: not-allowed;
  color: grey;
  background-color: unset;
}

.grid-cell-button:hover {
  background-color: rgba(255, 255, 255, 0.3);
}

.grid-cell-button.light:hover {
  background-color: rgba(5, 5, 5, 0.3);
}

.grid-cell-button.selected {
  background-color: rgba(255, 255, 255, 0.1);
}

.grid-cell-button.selected:hover {
  background-color: rgba(255, 255, 255, 0.2);
}

.grid-cell-button.selected.light {
  background-color: rgba(0, 0, 0, 0.1);
}

.grid-cell-button.selected.light:hover {
  background-color: rgba(0, 0, 0, 0.1);
}

.grid-cell-input {
  resize: none;
  height: 100%;
  width: 100%;
  font-size: 1.5em;
  font-weight: 400;
  border: 0;
  padding: 10px 20px;
  background: black;
  color: white;
}

.grid-cell-input:focus {
  border-radius: 0;
  outline: none;
}

.grid-cell-input.light {
  background: white;
  color: black;
}

